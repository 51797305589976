import type {Address, Cart, CartItem, Customer} from "~/models/cart";


export const useCart = () => ({
        createCart: async (
            productId: string,
            variantId: string,
            currency: string,
            sellerHandle: string,
            quantity: number = 1
        ) => {
            const {data} = await useApiFetch(
                '/cart',
                {
                    method: 'POST',
                    body: JSON.stringify({
                        items: [{
                            product_id: productId,
                            variant_id: variantId,
                            quantity,
                            shop_handle: sellerHandle
                        }],
                        currency,
                        channel: 'website'
                    })
                }
            )
            return data
        },
        async getCart(cartId: string): Promise<Cart | null> {
            const {data} = await useApiFetch(
                `/cart/${cartId}`
            )
            return data.value
        },

        async updateCart(cartId: string, items: CartItem[]): Promise<Cart | null> {
            const {data} = await useApiFetch<Cart>(
                `/cart/${cartId}`,
                {
                    method: 'PATCH',
                    body: JSON.stringify({
                        items: items.map(item => ({
                            product_id: item.product_id,
                            variant_id: item.variant_id,
                            quantity: item.quantity,
                            shop_handle: item.shop_handle
                        }))
                    })
                })
            return data.value
        },

        async startPayment(cartId: string, customer: Customer, shippingAddress: Address, newsletter: boolean, shopHandle: string): Promise<string> {
            const {data} = await useApiFetch(
                `/cart/${cartId}/payment`,
                {
                    method: 'POST',
                    body: JSON.stringify({
                        customer,
                        newsletter,
                        shop_handle: shopHandle,
                        shipping_address: shippingAddress
                    })
                }
            )
            return data.value.client_secret
        }

    }
)